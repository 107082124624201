import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "@components/layout"
import Seo from "@components/seo"
import GatsbyImageWrapper from "@components/global/GatsbyImageWrapper"
import Parallax from "@components/culture/Parallax"

import "@styles/pages/culture.scss"

export const query = graphql`
  query cultureFields {
    wpPage(databaseId: { eq: 145 }) {
      seo {
        metaDesc
        readingTime
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        title
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          localFile {
            publicURL
          }
        }
        opengraphType
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
      culture {
        slideshow {
          altText
          localFile {
            ext
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

const CulturePage = ({
  data: {
    wpPage: { seo, culture },
  },
}) => {
  const [scrollPosition, setScrollPosition] = useState(0)
  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <Layout>
      <Seo title="Culture" seo={seo} />
      <div className="culture-background">
        <div className="culture-background-inner">
          <StaticImage
            src="https://wordpress-742363-2492908.cloudwaysapps.com/wp-content/uploads/2022/05/Spray-Circle-for-web.jpg"
            alt=""
          />
        </div>
      </div>
      <div className="culture-scroll relative">
        <div className="container-new">
          <div className="row parallax-spacer"></div>
          {culture.slideshow.map((image, index) => {
            const offset = Math.floor(Math.random() * 100 + 50)
            return (
              <Parallax key={index} offset={offset}>
                <div className="row">
                  <div
                    className={`col-7 col-sm-5 col-md-3 ${
                      index % 3 === 0
                        ? "me-auto ms-auto"
                        : index % 3 === 1
                        ? "me-auto"
                        : "ms-auto"
                    }`}
                  >
                    <GatsbyImageWrapper image={image} />
                  </div>
                </div>
              </Parallax>
            )
          })}
          <div className="row parallax-spacer"></div>
        </div>
      </div>
      <div
        className="culture-title-wrap font-HelveticaNeue-Light"
        style={{ position: scrollPosition === 0 && "absolute" }}
      >
        <div className="culture-title">
          <h1>
            Creative&reg; <br />
            Intelligence&trade;
          </h1>
        </div>
      </div>
    </Layout>
  )
}

export default CulturePage
