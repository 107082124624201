import * as React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"

const GatsbyImageWrapper = props => {
  const { image, alt, loading, width, height, className } = props
  if (image) {
    return image.localFile ? (
      <>
        {image.localFile.ext === ".svg" || image.localFile.ext === ".gif" ? (
          <img
            src={image.localFile.publicURL}
            alt={alt || image.altText || ""}
            loading={loading ?? "lazy"}
            width={width || (image.mediaDetails && image.mediaDetails.width)}
            height={height || (image.mediaDetails && image.mediaDetails.height)}
            className={className}
          />
        ) : (
          <GatsbyImage
            image={image.localFile.childImageSharp.gatsbyImageData}
            alt={alt || image.altText || ""}
            loading={loading ?? "lazy"}
            width={width}
            height={height}
            className={className}
          />
        )}
      </>
    ) : (
      console.log("FAILED IMAGE?:", image)
    )
  } else return console.log("FAILED IMAGE 2 ?:", image)
}

GatsbyImageWrapper.propTypes = {
  image: PropTypes.object.isRequired,
  alt: PropTypes.string,
  loading: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
}

export default GatsbyImageWrapper
